var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"kpi-container"},[_c('div',{staticClass:"kpi-row"},[_c('div',{staticClass:"kpi-card kpi-card-light d-flex flex-column justify-content-between",staticStyle:{"padding":"1.2143rem","background-color":"#00455F","min-height":"112px"}},[_c('div',{staticClass:"text-white",staticStyle:{"font-weight":"600","font-size":"14px"}},[_vm._v(" Os Meus Pedidos ")]),_c('div',{staticClass:"d-flex flex-row align-items-center justify-content-between",staticStyle:{"margin-top":"10px"}},[_c('div',{staticClass:"d-flex flex-row align-items-center flex-wrap",staticStyle:{"flex":"5"}},[_c('div',{staticClass:"text-white d-flex align-items-center",staticStyle:{"font-weight":"600","font-size":"14px","margin-right":"60px"}},[_vm._v(" Em aberto "),_c('div',{staticStyle:{"margin-left":"12px","font-size":"40px"}},[_vm._v(" "+_vm._s(_vm.onAccepting)+" ")])]),_c('div',{staticClass:"text-white d-flex align-items-center",staticStyle:{"font-weight":"600","font-size":"14px","margin-right":"60px"}},[_vm._v(" Em Aprovação "),_c('div',{staticStyle:{"margin-left":"12px","font-size":"40px"}},[_vm._v(" "+_vm._s(_vm.onApproval)+" ")])]),_c('div',{staticClass:"text-white d-flex align-items-center",staticStyle:{"font-weight":"600","font-size":"14px","margin-right":"60px"}},[_vm._v(" Aprovados "),_c('div',{staticStyle:{"margin-left":"12px","font-size":"40px"}},[_vm._v(" "+_vm._s(_vm.approved)+" ")])])]),_c('div',{staticClass:"buttons justify-content-end mb-0 mt-0",staticStyle:{"flex":"1"}},[_c('div',{staticClass:"button primary",on:{"click":function($event){return _vm.$router.push({name: 'facilitator'})}}},[_vm._v(" Ver os meus pedidos "),_c('b-icon',{attrs:{"icon":"arrow-right-circle"}})],1)])])])]),_c('card-kpi-companies',{attrs:{"stats":_vm.stats,"active":_vm.active,"header":[
      {
        value: 1,
        label: 'Os meus diagnósticos'
      },
      {
        value: 2,
        label: 'C/ Facilitador'
      }
    ],"set-active":function (value) {_vm.active = value},"date1":_vm.date1,"set-date1":function (value) {
      _vm.date1 = value
    },"date2":_vm.date2,"set-date2":function (value) {
      _vm.date2 = value
    },"search":_vm.search}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }