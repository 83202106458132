<template>
  <div class="kpi-container">
    <div class="kpi-row">
      <div
        class="kpi-card kpi-card-light d-flex flex-column justify-content-between"
        style="padding: 1.2143rem; background-color: #00455F; min-height: 112px"
      >
        <div
          class="text-white"
          style="font-weight: 600; font-size: 14px"
        >
          Os Meus Pedidos
        </div>
        <div
          class="d-flex flex-row align-items-center justify-content-between"
          style="margin-top: 10px"
        >
          <div
            class="d-flex flex-row align-items-center flex-wrap"
            style="flex: 5"
          >
            <div
              class="text-white d-flex align-items-center"
              style="font-weight: 600; font-size: 14px; margin-right: 60px"
            >
              Em aberto
              <div style="margin-left: 12px; font-size: 40px">
                {{ onAccepting }}
              </div>
            </div>
            <div
              class="text-white d-flex align-items-center"
              style="font-weight: 600; font-size: 14px; margin-right: 60px"
            >
              Em Aprovação
              <div style="margin-left: 12px; font-size: 40px">
                {{ onApproval }}
              </div>
            </div>
            <div
              class="text-white d-flex align-items-center"
              style="font-weight: 600; font-size: 14px; margin-right: 60px"
            >
              Aprovados
              <div style="margin-left: 12px; font-size: 40px">
                {{ approved }}
              </div>
            </div>
          </div>
          <div
            class="buttons justify-content-end mb-0 mt-0"
            style="flex: 1"
          >
            <div
              class="button primary"
              @click="$router.push({name: 'facilitator'})"
            >
              Ver os meus pedidos
              <b-icon icon="arrow-right-circle" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <card-kpi-companies
      :stats="stats"
      :active="active"
      :header="[
        {
          value: 1,
          label: 'Os meus diagnósticos'
        },
        {
          value: 2,
          label: 'C/ Facilitador'
        }
      ]"
      :set-active="(value) => {active = value}"
      :date1="date1"
      :set-date1="(value) => {
        date1 = value
      }"
      :date2="date2"
      :set-date2="(value) => {
        date2 = value
      }"
      :search="search"
    />
  </div>
</template>

<script>
import { get } from '../../../services/api';
import CardKPICompanies from '../CardKPICompanies.vue';

export default {
  name: 'DashboardKPIFacilitator',
  components: { 'card-kpi-companies': CardKPICompanies },
  data() {
    return {
      loaded: false,
      quizzes: null,
      onAccepting: null,
      onApproval: null,
      approved: null,
      active: null,
      quiz_facilitator_stats: null,
      stats: null,
      date1: new Date(
        new Date().getFullYear() - 1,
        new Date().getMonth(),
        new Date().getDate(),
      ).toLocaleDateString(),
      date2: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
      ).toLocaleDateString(),
    };
  },
  watch: {
    active: {
      handler() {
        this.search();
      },
    },
  },
  async created() {
    await get('quiz_facilitator')
      .then((response) => {
        this.quizzes = response.data;
        this.onAccepting = this.quizzes.filter((quiz) => quiz.state == 1).length;
        this.onApproval = this.quizzes.filter((quiz) => quiz.state == 2).length;
        this.approved = this.quizzes.filter((quiz) => quiz.result == 1).length;
      });
    this.active = 1;
    this.loaded = true;
  },
  methods: {
    async search() {
      await get(`quiz_facilitator_stats?from=
        ${this.date1.split('/').reverse().join('-')}&to=${this.date2.split('/').reverse().join('-')}
      `)
        .then((response) => {
          this.quiz_facilitator_stats = response.data;
          if (this.active === 1) {
            this.stats = {
              big: this.quiz_facilitator_stats.my_big,
              pme: this.quiz_facilitator_stats.my_pme,
              micro: this.quiz_facilitator_stats.my_micro,
              quizzes: this.quiz_facilitator_stats.my_quizzes,
              submitted: this.quiz_facilitator_stats.my_submitted,
              media: this.quiz_facilitator_stats.my_media,
              companies_s2f: this.quiz_facilitator_stats.companies_s2f,
            };
          } else {
            this.stats = {
              big: this.quiz_facilitator_stats.big,
              pme: this.quiz_facilitator_stats.pme,
              micro: this.quiz_facilitator_stats.micro,
              quizzes: this.quiz_facilitator_stats.quizzes,
              submitted: this.quiz_facilitator_stats.submitted,
              media: this.quiz_facilitator_stats.media,
              companies_s2f: this.quiz_facilitator_stats.companies_s2f,
            };
          }
        });
    },
  },
};
</script>
